import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { Button, Tooltip, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components'

import CreatorCampaignHistory from './CreatorCampaignHistory'
import { CreatorFlags } from './CreatorFlags'
import { Rating } from './Rating'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import { OptInContext } from '../../../contexts/OptInContext'
import { UserContext } from '../../../contexts/UserContext'
import { Chat } from '../../../layout/chat/Chat'
import { getProfilePicUrl } from '../../../utils'
import Channels from '../../general/Channels'
import CopyAddress from '../../general/CopyAddress'
import CreatorAvatar from '../../general/CreatorAvatar'

const { REACT_APP_AFFILIATE_API_URL } = process.env

const OptInProfile = ({ optIn, setSelectedOptIn, close }) => {
  const { role } = useContext(UserContext)

  const [emailCopied, setEmailCopied] = useState(false)
  const [phoneCopied, setPhoneCopied] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)

  const { brandId, fetchBrandData } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const { activate, decline, reinvite } = useContext(OptInContext)

  const profilePicUrl = getProfilePicUrl(optIn)

  useEffect(() => {
    if (!optIn.seen) axios.post(`${NODE_URL}/brand/${brandId}/opt-in/${optIn.id}/view`)
  }, [optIn])

  const handleActivate = async () => {
    setActionLoading('activate')
    let result = await activate(optIn.id)
    setActionLoading(false)

    if (result.error) {
      message.error('Something went wrong. Please try again or contact campaignsupport@creator.co')
    } else {
      message.success('Creator has been activated!')
      queryCache.invalidateQueries('unseen-opt-ins')
      queryCache.invalidateQueries('opt-ins') // inbox opt-ins
      optIn.next ? setSelectedOptIn(optIn.next) : close()
    }
  }

  const handleDecline = async () => {
    setActionLoading('decline')
    let result = await decline(optIn.id)
    setActionLoading(false)

    if (result.error) {
      message.error('Something went wrong. Please try again or contact campaignsupport@creator.co')
    } else {
      message.success('Creator declined')
      queryCache.invalidateQueries('unseen-opt-ins')
      queryCache.invalidateQueries('opt-ins') // inbox opt-ins
      optIn.next ? setSelectedOptIn(optIn.next) : close()
    }
  }

  const handleReinvite = async () => {
    if (optIn.reinvited) return // prevent sending multiple reinvites

    setActionLoading('reinvite')
    let result = await reinvite(optIn.id)
    setActionLoading(false)

    if (!result.error) {
      message.success('Creator reinvited')
      optIn.next ? setSelectedOptIn(optIn.next) : close()
    } else {
      message.error(result.error)
    }
  }

  const [affiliateCopied, setAffiliateCopied] = useState([])
  const copyAffiliateLink = i => {
    setAffiliateCopied(prev => ({ ...prev, [i]: true }))
    setTimeout(() => {
      setAffiliateCopied(prev => ({ ...prev, [i]: false }))
    }, 1000)
  }

  const invited = optIn.campaignInvites?.find(invite => invite.campaignId === optIn.campaign.id)

  return (
    <Wrapper>
      <section className='profile-section'>
        <div className='creator-info'>
          <div className='basic-info'>
            <div className='creator'>
              <CreatorAvatar
                className='avatar'
                size={80}
                id={optIn.user.id}
                url={profilePicUrl}
                initials={`${optIn.user?.firstName?.charAt(0).toUpperCase()} ${optIn.user?.lastName
                  ?.charAt(0)
                  .toUpperCase()}`}
              />
              <div>
                <h1 className='name'>{`${optIn.user.firstName} ${optIn.user.lastName}`}</h1>
                <Rating userId={optIn.user.id} optIn={optIn} />
                {invited && (
                  <div className='invited'>
                    <MailOutlined /> <p>Invited on {moment(invited.createdAt).format('ll')}</p>
                  </div>
                )}
              </div>
            </div>
            <div className='actions'>
              {!optIn.campaign.metaData?.paymentBeta && optIn.status === 'pending' && (
                <>
                  <Button
                    type='primary'
                    icon={<CheckCircleOutlined />}
                    loading={actionLoading === 'activate'}
                    disabled={actionLoading === 'decline'}
                    onClick={handleActivate}
                    style={{ width: '120px' }}>
                    Activate
                  </Button>
                  <Button
                    type='ghost'
                    danger
                    icon={<CloseCircleOutlined />}
                    loading={actionLoading === 'decline'}
                    disabled={actionLoading === 'activate'}
                    onClick={handleDecline}
                    style={{ width: '120px' }}>
                    Decline
                  </Button>
                </>
              )}
              {optIn.status === 'cancelled' && (
                <Button
                  type='primary'
                  loading={actionLoading === 'reinvite'}
                  disabled={optIn.reinvited}
                  onClick={handleReinvite}>
                  {optIn.reinvited ? 'Reinvited' : 'Reinvite'}
                </Button>
              )}
            </div>
          </div>
          {role === 'administrator' && <CreatorFlags userId={optIn.user.id} />}
          <div className='niches-address'>
            <div className='niches'>
              <h2 className='title'>Niche</h2>
              <div className='niche-tags'>
                {optIn.user.creatorProfile?.niches?.length ? (
                  optIn.user.creatorProfile.niches.map(({ category: creatorNiche }) => (
                    <span
                      key={creatorNiche.id}
                      className={`niche ${
                        optIn.campaign.categories?.filter(
                          ({ category: campaignNiche }) => campaignNiche.slug === creatorNiche.slug
                        )[0] && 'match'
                      }`}>
                      {creatorNiche.title}
                    </span>
                  ))
                ) : (
                  <span className='niche'>No niches selected.</span>
                )}
              </div>
            </div>
            <div className='personal'>
              {(optIn.status === 'activated' || optIn.status === 'completed') && (
                <div className='contact-info'>
                  <CopyToClipboard
                    text={optIn.user.email}
                    onCopy={() => {
                      setEmailCopied(true)
                      setTimeout(() => setEmailCopied(false), 1000)
                    }}>
                    <span className='copy'>
                      <Tooltip open={emailCopied} title='Copied to clipboard'>
                        {optIn.user.email}
                      </Tooltip>
                    </span>
                  </CopyToClipboard>
                  {optIn.user.phone && (
                    <CopyToClipboard
                      text={`+${optIn.user.phoneCode} ${optIn.user.phone}`}
                      onCopy={() => {
                        setPhoneCopied(true)
                        setTimeout(() => setPhoneCopied(false), 1000)
                      }}>
                      <span className='copy'>
                        <Tooltip open={phoneCopied} title='Copied to clipboard'>
                          {`+${optIn.user.phoneCode || ''} ${optIn.user.phone}`}{' '}
                          {optIn.user.extraData?.twilioVerified && (
                            <CheckCircleOutlined style={{ color: '#027DF0', fontSize: '12px' }} />
                          )}
                        </Tooltip>
                      </span>
                    </CopyToClipboard>
                  )}
                </div>
              )}
              <CopyAddress
                address={{
                  firstName: optIn.user.firstName,
                  lastName: optIn.user.lastName,
                  ...optIn.user.creatorProfile,
                }}
              />
            </div>
          </div>

          <Channels optIn={optIn} />
        </div>

        {!!optIn.shopifyDiscountCodes?.length && (
          <div className='coupon-codes'>
            <h2>Shopify Coupon Code</h2>
            <b>{optIn.shopifyDiscountCodes[0].code}</b>
          </div>
        )}

        {/* RAKUTEN */}
        {brandData?.extraData?.rakutenId &&
          optIn.campaign.metaData?.rakutenAffiliateLinks?.length && (
            <div className='affiliate-links'>
              <h2 className='title'>Affiliate Links</h2>
              {optIn.campaign.metaData.rakutenAffiliateLinks.map((affiliateLink, i) => {
                const url = `${REACT_APP_AFFILIATE_API_URL}/rk-${optIn.id}-${i + 1}`

                return (
                  <div key={i} className='link' style={{ overflowWrap: 'break-word' }}>
                    <CopyToClipboard text={url}>
                      <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                        <span
                          style={{
                            overflowWrap: 'break-word',
                            cursor: 'pointer',
                            color: '#027DF0',
                          }}
                          onClick={() => copyAffiliateLink(i)}
                          onKeyDown={() => copyAffiliateLink(i)}
                          role='button'
                          tabIndex={0}>
                          {url}
                          <CopyOutlined />
                        </span>
                      </Tooltip>
                    </CopyToClipboard>
                    <p className='description'>{affiliateLink.description}</p>
                  </div>
                )
              })}
            </div>
          )}

        {/* AWIN */}
        {brandData?.extraData?.awinId && optIn.campaign.metaData?.affiliateLinks?.length && (
          <div className='affiliate-links'>
            <h2 className='title'>Affiliate Links</h2>
            {optIn.campaign.metaData.affiliateLinks.map((affiliateLink, i) => {
              const url = `${REACT_APP_AFFILIATE_API_URL}/aw-${optIn.id}-${i + 1}`

              return (
                <div key={i} className='link'>
                  <CopyToClipboard text={url}>
                    <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                      <span
                        style={{
                          overflowWrap: 'break-word',
                          cursor: 'pointer',
                          color: '#027DF0',
                        }}
                        onClick={() => copyAffiliateLink(i)}
                        onKeyDown={() => copyAffiliateLink(i)}
                        role='button'
                        tabIndex={0}>
                        {url}
                        <CopyOutlined />
                      </span>
                    </Tooltip>
                  </CopyToClipboard>
                  <p className='description'>{affiliateLink.description}</p>
                </div>
              )
            })}
          </div>
        )}

        {/* IMPACT RADIUS */}
        {brandData?.extraData?.impactRadiusId &&
          optIn.campaign.metaData?.impactRadiusAffiliateLinks?.length && (
            <div className='affiliate-links'>
              <h2 className='title'>Affiliate Links</h2>
              {optIn.campaign.metaData.impactRadiusAffiliateLinks.map((affiliateLink, i) => {
                const url = `${REACT_APP_AFFILIATE_API_URL}/ir-${optIn.id}-${i + 1}`

                return (
                  <div key={i} className='link'>
                    <CopyToClipboard text={url}>
                      <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                        <span
                          style={{
                            overflowWrap: 'break-word',
                            cursor: 'pointer',
                            color: '#027DF0',
                          }}
                          onClick={() => copyAffiliateLink(i)}
                          onKeyDown={() => copyAffiliateLink(i)}
                          role='button'
                          tabIndex={0}>
                          {url}
                          <CopyOutlined />
                        </span>
                      </Tooltip>
                    </CopyToClipboard>
                    <p className='description'>{affiliateLink.description}</p>
                  </div>
                )
              })}
            </div>
          )}

        {/* CJ AFFILIATE */}
        {brandData?.extraData?.cjId && optIn.campaign.metaData?.cjAffiliateLinks?.length && (
          <div className='affiliate-links'>
            <h2 className='title'>Affiliate Links</h2>
            {optIn.campaign.metaData.cjAffiliateLinks.map((affiliateLink, i) => {
              const url = `${REACT_APP_AFFILIATE_API_URL}/cj-${optIn.id}-${i + 1}`

              return (
                <div key={i} className='link'>
                  <CopyToClipboard text={url}>
                    <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                      <span
                        style={{
                          overflowWrap: 'break-word',
                          cursor: 'pointer',
                          color: '#027DF0',
                        }}
                        onClick={() => copyAffiliateLink(i)}
                        onKeyDown={() => copyAffiliateLink(i)}
                        role='button'
                        tabIndex={0}>
                        {url}
                        <CopyOutlined />
                      </span>
                    </Tooltip>
                  </CopyToClipboard>
                  <p className='description'>{affiliateLink.description}</p>
                </div>
              )
            })}
          </div>
        )}

        {/* CREATOR.CO */}
        {!!optIn.brandAffiliateLink?.length && (
          <div className='affiliate-links'>
            <h2 className='title'>Affiliate Links</h2>
            {optIn.brandAffiliateLink?.map((affiliateLink, i) => {
              const url = `${REACT_APP_AFFILIATE_API_URL}/cr-${optIn.id}-${affiliateLink.id}`

              return (
                <div key={i} className='link'>
                  <CopyToClipboard text={url}>
                    <Tooltip trigger='click' open={affiliateCopied[i]} title='Copied!'>
                      <span
                        style={{
                          overflowWrap: 'break-word',
                          cursor: 'pointer',
                          color: '#027DF0',
                        }}
                        onClick={() => copyAffiliateLink(i)}
                        onKeyDown={() => copyAffiliateLink(i)}
                        role='button'
                        tabIndex={0}>
                        {url}
                        <CopyOutlined />
                      </span>
                    </Tooltip>
                  </CopyToClipboard>
                  <p className='description'>{affiliateLink?.description}</p>
                </div>
              )
            })}
          </div>
        )}
        <div className='campaign-history'>
          <h2 className='title'>Campaign History</h2>
          <CreatorCampaignHistory creatorId={optIn.user.id} name={optIn.user.firstName} />
        </div>
      </section>
      <section>
        <Chat drawer optInId={optIn.id} />
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  height: 100%;

  section {
    flex: 1;
  }

  h2 {
    font-size: 18px;
    font-family: 'Gilroy-ExtraBold', sans-serif;
    color: #23262f;
  }

  .profile-section {
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    ${props => props.theme.scrollbar}
    & > div {
      padding: 20px;
    }
    .title {
      font-size: 18px;
      color: #23262f;
    }
    p {
      white-space: pre-line;
    }
  }

  .basic-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .creator {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      .id {
        color: rgba(0, 0, 0, 0.2);
        font-size: 12px;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
      .name {
        font-family: 'Gilroy-ExtraBold', sans-serif;
        font-size: 20px;
        margin: 0;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  .niches-address {
    display: flex;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 20px;
    .niches {
      flex: 1;
      padding: 10px;
      .niche-tags {
        display: flex;
        flex-wrap: wrap;
        .niche {
          background: #f2f2f2;
          color: rgba(0, 0, 0, 0.4);
          border-radius: 5px;
          text-align: center;
          padding: 2px 6px;
          font-size: 0.7rem;
          margin: 0 5px 5px 0;
          &.match {
            background: ${props => props.theme.crcoPaleBlue};
            color: ${props => props.theme.crcoTechBlue};
          }
        }
      }
    }
    .personal {
      border-left: 1px solid #e6e6e6;
      padding: 10px;
      flex: 1;
      .contact-info {
        margin-bottom: 20px;
      }
      .copy {
        cursor: pointer;
        color: #666;
        display: flex;
        transition: 0.2s ease-in-out;
        &:hover {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
    }
  }

  .affiliate-links {
    p {
      color: ${props => props.theme.crcoGrey};
    }
  }

  .campaign-history {
    border-top: 1px solid #e6e6e6;
  }
  .invited {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: 0.8rem;
    color: ${props => props.theme.crcoGrey};
    p {
      padding-left: 5px;
    }
  }
`

export default OptInProfile
