import {
  SearchOutlined,
  InfoCircleOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { Empty, Button, Spin, Tooltip, message } from 'antd'
import { Form, Formik } from 'formik'
import { DatePicker, Input, Select } from 'formik-antd'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useInfiniteQuery, useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { ShoutoutsListItem } from './ShoutoutsListItem'
import StoriesList from './StoriesList'
import { UploadsListItem } from './UploadsListItem'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import { downloadBlob } from '../../../../custom-hooks/fileDownload'

const { Option } = Select

const Content = () => {
  const {
    brandId,
    selectedCampaignID,
    fetchShoutouts,
    fetchUploads,
    fetchCampaign,
    exportStories,
    exportShoutouts,
  } = useContext(CampaignContext)

  const [exportLoading, setExportLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [uploadsFormData, setUploadsFormData] = useState({})
  const [display, setDisplay] = useState(
    // get preferred display from local storage or set to shoutouts as default
    localStorage.getItem('shoutoutsDisplay') || 'shoutouts'
  )
  const [view, setView] = useState(
    // get preferred view from local storage or set to grid as default
    localStorage.getItem('shoutoutsView') || 'grid'
  )

  const submitRef = useRef(0)
  const scrollRef = useRef(false)
  const uploadsScrollRef = useRef(false)

  const { data: campaign } = useQuery(['campaign', selectedCampaignID], fetchCampaign, {
    retry: (failureCount, error) => !error.response?.status === 404,
  })

  const {
    status: shoutoutStatus,
    data: shoutoutsData,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(
    ['shoutouts', { campaignID: selectedCampaignID, formData }],
    fetchShoutouts,
    {
      getFetchMore: lastGroup => {
        return lastGroup?.nextCursor
      },
    }
  )

  const {
    status: uploadsStatus,
    data: uploadsData,
    isFetchingMore: isFetchingMoreUploads,
    fetchMore: fetchMoreUploads,
    canFetchMore: canFetchMoreUploads,
  } = useInfiniteQuery(
    ['content-uploads', { campaignID: selectedCampaignID, formData: uploadsFormData }],
    fetchUploads,
    {
      getFetchMore: lastGroup => {
        return lastGroup?.nextPage
      },
    }
  )

  const handleScroll = useCallback(
    currentScroll => {
      const distance = currentScroll
        ? currentScroll.scrollHeight - (currentScroll.scrollTop + currentScroll.offsetHeight)
        : 0
      if (distance < 400 && canFetchMore && !isFetchingMore) {
        fetchMore()
      }
    },
    [canFetchMore, fetchMore, isFetchingMore]
  )

  const handleScrollUploads = useCallback(
    currentScroll => {
      const distance = currentScroll
        ? currentScroll.scrollHeight - (currentScroll.scrollTop + currentScroll.offsetHeight)
        : 0
      if (distance < 400 && canFetchMoreUploads && !isFetchingMoreUploads) {
        fetchMoreUploads()
      }
    },
    [canFetchMoreUploads, fetchMoreUploads, isFetchingMoreUploads]
  )

  const handleExport = async () => {
    try {
      setExportLoading(true)
      const isShoutouts = display === 'shoutouts'
      const exportFunction = isShoutouts ? exportShoutouts : exportStories
      const response = await exportFunction(brandId, selectedCampaignID, formData)

      if (response?.length === 0) {
        message.warn('No content')
      } else {
        const blob = new Blob([response])
        const fileName = `${campaign.title}-${display}.csv`
        downloadBlob(blob, fileName)
      }
    } catch (err) {
      message.error(
        'Download failed, please try again. If this issue persists please contact support.',
        10
      )
    } finally {
      setExportLoading(false)
    }
  }

  useEffect(() => {
    if (scrollRef.current) {
      handleScroll(scrollRef.current)
    }
  }, [shoutoutsData, handleScroll])

  return (
    <Wrapper>
      <div className='inner'>
        <Formik
          initialValues={{
            sort: 'posted-desc',
          }}
          onSubmit={data => {
            submitRef.current++
            const thisSubmit = submitRef.current
            setTimeout(() => {
              if (thisSubmit === submitRef.current) {
                if (display === 'shoutouts') {
                  setFormData(prev => ({ ...prev, ...data }))
                } else if (display === 'uploads') {
                  setUploadsFormData(prev => ({
                    ...prev,
                    fromDate: data.uploadsFromDate,
                    toDate: data.uploadsToDate,
                    search: data.uploadsSearch,
                  }))
                }
              }
            }, 300)
          }}>
          {({ submitForm }) => (
            <Form className='filters'>
              <div className='toggle-btns'>
                {display === 'shoutouts' && (
                  <>
                    <Button
                      type='link'
                      className={`toggle-btn ${view === 'list' && 'active'}`}
                      onClick={() => {
                        localStorage.setItem('shoutoutsView', 'list')
                        setView('list')
                      }}>
                      <UnorderedListOutlined />
                    </Button>
                    <Button
                      type='link'
                      className={`toggle-btn ${view === 'grid' && 'active'}`}
                      onClick={() => {
                        localStorage.setItem('shoutoutsView', 'grid')
                        setView('grid')
                      }}>
                      <AppstoreOutlined />
                    </Button>
                  </>
                )}
              </div>
              <Select
                style={{ width: '200px' }}
                name='display'
                placeholder='Switch content view'
                defaultValue={display}
                onChange={value => {
                  localStorage.setItem('shoutoutsDisplay', value)
                  setDisplay(value)
                }}>
                <Option value='shoutouts'>Live ShoutOuts</Option>
                {campaign?.socialChannels?.includes('instagram') && (
                  <Option value='stories'>Instagram Stories</Option>
                )}
                <Option value='uploads'>
                  <OptionWrapper>
                    Chat Uploads
                    {campaign?.unreadUploadCount ? (
                      <UnseenCount>{campaign?.unreadUploadCount} </UnseenCount>
                    ) : (
                      ''
                    )}
                  </OptionWrapper>
                </Option>
              </Select>
              {display === 'shoutouts' && (
                <>
                  <div className='date-filter'>
                    <DatePicker placeholder='From date' name='fromDate' onChange={submitForm} />
                    <DatePicker placeholder='To date' name='toDate' onChange={submitForm} />
                  </div>
                  <Select style={{ flex: '1' }} name='sort' onChange={submitForm}>
                    <Option value='posted-desc'>Newest</Option>
                    <Option value='posted-asc'>Oldest</Option>
                  </Select>
                  <Input
                    style={{ flex: '2', minWidth: '250px' }}
                    onChange={submitForm}
                    name='search'
                    placeholder='Search by creator'
                    suffix={<SearchOutlined />}
                  />
                </>
              )}
              {display === 'stories' && (
                <>
                  <div className='hashtags'>
                    <span className='label'>
                      <InfoCircleOutlined /> Tags
                    </span>
                    <div className='info'>
                      <span className='arrow' />
                      <p className='info-text'>Hashtags & mentions required to capture stories:</p>
                      <p>
                        {campaign?.storyMentions?.length
                          ? campaign.storyMentions.map(mention => `@${mention}`).join(', ')
                          : 'No mentions required.'}
                      </p>
                      <p>
                        {campaign?.storyHashtags?.length
                          ? campaign.storyHashtags.map(hashtag => `#${hashtag}`).join(', ')
                          : 'No hashtags required.'}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {display === 'uploads' && (
                <>
                  <div className='date-filter'>
                    <DatePicker
                      placeholder='From date'
                      name='uploadsFromDate'
                      onChange={submitForm}
                    />
                    <DatePicker placeholder='To date' name='uploadsToDate' onChange={submitForm} />
                  </div>
                  <Input
                    style={{ flex: '2', minWidth: '250px' }}
                    onChange={submitForm}
                    name='uploadsSearch'
                    placeholder='Search by creator'
                    suffix={<SearchOutlined />}
                  />
                </>
              )}
              {(display === 'shoutouts' || display === 'stories') && (
                <Tooltip arrow title={`Export Metrics`}>
                  <Button
                    className='download-btn'
                    type='link'
                    disabled={exportLoading}
                    loading={exportLoading}
                    onClick={handleExport}
                    icon={<DownloadOutlined />}
                  />
                </Tooltip>
              )}
            </Form>
          )}
        </Formik>

        {/* LIVE SHOUTOUTS */}
        {display === 'shoutouts' && (
          <>
            {shoutoutStatus === 'success' && (
              <>
                {shoutoutsData[0]?.totalResults ? (
                  <div
                    className={`content-list ${view}`}
                    ref={scrollRef}
                    onScroll={() => handleScroll(scrollRef.current)}>
                    {shoutoutsData.map(group =>
                      group.socialPosts.map(socialPost => (
                        <ShoutoutsListItem key={socialPost.id} socialPost={socialPost} />
                      ))
                    )}
                  </div>
                ) : (
                  <div className='no-results'>
                    {Object.keys(formData).length ? (
                      <Empty description='No results.' />
                    ) : (
                      <Empty description='No ShoutOuts yet.' />
                    )}
                  </div>
                )}
              </>
            )}
            {shoutoutStatus === 'loading' && (
              <div className='loading'>
                <Spin />
              </div>
            )}
          </>
        )}

        {/* IG STORIES */}
        {display === 'stories' && <StoriesList />}

        {/* CHAT UPLOADS */}
        {display === 'uploads' && (
          <>
            {uploadsStatus === 'success' && (
              <>
                {uploadsData[0].totalResults ? (
                  <div
                    className={`content-list grid`}
                    ref={uploadsScrollRef}
                    onScroll={() => handleScrollUploads(uploadsScrollRef.current)}>
                    {uploadsData.map(group =>
                      group.uploads.map((upload, key) => (
                        <UploadsListItem key={key} upload={upload} brandId={brandId} />
                      ))
                    )}
                  </div>
                ) : (
                  <div className='no-results'>
                    {Object.keys(uploadsFormData).length ? (
                      <Empty description='No results.' />
                    ) : (
                      <Empty description='No uploads yet.' />
                    )}
                  </div>
                )}
              </>
            )}
            {uploadsStatus === 'loading' && (
              <div className='loading'>
                <Spin />
              </div>
            )}
          </>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  & > .inner {
    position: absolute;
    top: 24px;
    bottom: 24px;
    left: 24px;
    right: 24px;
    display: flex;
    flex-direction: column;
  }

  .filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 15px 15px 15px;
    box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.2);
    z-index: 1;
    .hashtags {
      position: relative;
      .label {
        cursor: pointer;
        transition: 0.2s ease-in-out;
      }
      .info {
        background: #d2e9f9;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-in-out;
        padding: 20px;
        position: absolute;
        right: -10px;
        border-radius: 10px;
        top: 35px;
        width: 300px;
        .arrow {
          background: #d2e9f9;
          height: 10px;
          width: 10px;
          transform: rotate(45deg);
          position: absolute;
          right: 30px;
          top: -5px;
        }
        .info-text {
          font-weight: bold;
        }
      }
      &:hover {
        .label {
          color: ${props => props.theme.crcoTechBlue};
        }
        .info {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .download-btn {
      margin-left: auto;
    }
  }
  .toggle-btns {
    display: flex;
    align-items: center;
    gap: 12px;
    .toggle-btn {
      color: ${props => props.theme.crcoMidnight};
      font-size: 1.2rem;
      padding: 0;
      border: 0;
      margin: 0;
      &:hover,
      &.active {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  .date-filter {
    display: flex;
    gap: 8px;
    width: 250px;
    .ant-picker {
      flex: 1;
    }
  }

  .content-list {
    background: #f2f2f2;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    padding: 20px;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
    ${props => props.theme.scrollbar}
    &.grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
      .content-wrapper {
        flex-direction: column;
        text-align: center;
        padding: 5px;
        gap: 5px;
        .thumbnail {
          height: 270px;
          width: 100%;
        }
        .caption {
          margin: 5px;
        }
        .stats {
          margin: auto 0 0 0;
          padding-top: 10px;
          border-top: 1px solid #eee;
        }
      }
      .loading-card {
        min-height: 350px;
      }
    }
    &.list {
      flex-direction: column;
      gap: 10px;
      .content-wrapper {
        align-items: center;
        gap: 30px;
        .carousel-icon {
          padding: 6px;
          top: 4px;
          left: 4px;
          img {
            height: 15px;
            width: 15px;
          }
        }
        iframe,
        .thumbnail {
          height: 100px;
          min-width: 100px;
          max-width: 100px;
          flex: 1;
        }
        .text {
          flex: 2;
          margin-right: 20px;
        }
        .caption {
          margin: 0;
        }
        .stats {
          flex: 1;
          max-width: 350px;
          margin-left: auto;
        }
        .action-button {
          margin-left: auto;
        }
      }
      .card-loading {
        min-height: 180px;
      }
    }
    .content-wrapper {
      height: fit-content;
      background-color: #fff;
      border: 5px solid #fff;
      border-radius: 5px;
      transition: 0.2s ease-in-out;
      cursor: pointer;
      display: flex;
      position: relative;
      .carousel-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(5px);
        padding: 10px;
        border-radius: 50%;
        font-size: 1.2rem;
        display: grid;
        place-content: center;
        img {
          height: 20px;
          width: 20px;
          filter: invert(1);
        }
      }
      iframe,
      .thumbnail {
        background-color: #e6e6e6;
        border: 1px solid #eee;
        border-radius: 5px;
        height: 270px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &.placeholder {
          display: grid;
          place-content: center;
          .anticon {
            font-size: 50px;
            opacity: 0.1;
          }
        }
      }
      .user-info {
        margin: 5px 0;
        .user-info-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .flag {
            height: 20px;
            width: 20px;
          }
        }
        .name {
          color: #027df0;
        }
        .followers {
          color: #333;
          font-size: 0.8rem;
        }
      }

      .notification-badge {
        position: absolute;
        background: orange;
        border-radius: 10px;
        width: 15px;
        height: 15px;
        padding: 2px 5px 0 5px;
        color: #fff;
        font-size: 0.6rem;
        top: 0;
        right: 0;
      }

      .not-connected {
        margin-left: 5px;
        color: red;
        font-size: 0.8rem;
        &.expired {
          color: orange;
        }
      }
      p {
        margin: 0;
      }
      .caption {
        font-family: 'Campton-Light', sans-serif;
        font-size: 0.8rem;
        margin-top: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 600px;
      }
      .date {
        color: #999;
        font-size: 0.7rem;
      }
      .stats {
        display: flex;
        gap: 30px;
        margin-top: 5px;
        font-size: 12px;
        margin: 0 20px;
        .stat {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          flex: 1;

          .num {
            font-size: 18px;
            font-weight: bold;
          }

          &.none {
            opacity: 0.3;
          }
        }
        .icon {
          height: 20px;
          width: 20px;
        }
        .num {
          color: #444;
          font-size: 0.7rem;
        }
      }
      &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        .download-overlay {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .loading-card {
      background-color: #fff;
      border-radius: 5px;
      background-image: linear-gradient(90deg, #fff 0px, #fafafa 100px, #fff 300px);
      background-size: 100vw 100%;
      animation: shine 1.5s infinite ease-in-out;
    }
  }

  .no-results,
  .loading {
    background: #f2f2f2;
    height: 100%;
    width: 100%;
    border: 1px solid #eee;
    border-top: none;
    border-radius: 0 0 5px 5px;
    display: grid;
    place-items: center;
  }

  @keyframes shine {
    0% {
      background-position-x: -20vw;
    }
    80%,
    100% {
      background-position-x: 85vw;
    }
  }
`

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

const UnseenCount = styled.span`
  background: orange;
  border-radius: 10px;
  height: 15px;
  padding: 3px 5px 0 5px;
  color: #fff;
  font-size: 0.6rem;
  line-height: 1;
  display: inline-block;
  margin-left: auto;
`

export default Content
