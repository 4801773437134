import { PlusOutlined } from '@ant-design/icons'
import { Select, Button, Modal, message } from 'antd'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { Input } from 'formik-antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import numeral from 'numeral'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'

import { routes } from './routes'
import FormItem from '../components/forms/FormItem'
import { NODE_URL } from '../constants'
import { BrandContext } from '../contexts/BrandContext'
import { UserContext } from '../contexts/UserContext'
import admin from '../images/icons/dashboard/admin.svg'

const Sidebar = () => {
  const flags = useFlags()
  const history = useHistory()
  const { fetchCurrentUserData, userContextConfig } = useContext(UserContext)
  const { brandId, setBrandId, fetchCampaigns } = useContext(BrandContext)
  const { data: userData } = useQuery('current-user', fetchCurrentUserData, userContextConfig)
  const { data: campaigns } = useQuery(['campaigns', brandId], fetchCampaigns)

  const [firstCampaignId, setFirstCampaignId] = useState()
  const [numUnreadMessages, setNumUnreadMessages] = useState(0)
  const [createBrandOpen, setCreateBrandOpen] = useState(false)

  useEffect(() => {
    const activeCampaigns = campaigns?.filter(
      campaign => campaign.status === 'publish' || campaign.status === 'paused'
    )

    if (activeCampaigns?.length) {
      setNumUnreadMessages(
        activeCampaigns.reduce((acc, campaign) => acc + campaign.unreadMessageCount, 0)
      )
      setFirstCampaignId(activeCampaigns[0].id)
    }
  }, [campaigns, brandId])

  const selectBrand = value => {
    setBrandId(value)
    axios.post(`${NODE_URL}/me`, { selectedBrand: value })
    history.push('/campaigns')
  }

  // set selected brand for admins when
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlBrandId = urlParams.get('brand')

    if (urlBrandId && window.location.pathname.includes('inbox')) {
      if (userData?.role && userData?.role === 'administrator') {
        setBrandId(urlBrandId)
        axios.post(`${NODE_URL}/me`, { selectedBrand: urlBrandId })
      }
      // clear url param
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }, [userData, setBrandId])

  // #region admin all brands
  const [allBrands, setAllBrands] = useState([])
  const [fetchingBrands, setFetchingBrands] = useState(true)
  const brandFetch = useRef(0)

  useEffect(() => {
    if (userData.role && userData.role === 'administrator') {
      axios
        .get(`${NODE_URL}/brands`, {
          params: { sort: 'campaigns-desc' },
        })
        .then(res => setAllBrands(res.data))
        .catch(() => {})
        .finally(() => setFetchingBrands(false))
    }
  }, [userData])

  const handleSearchBrands = search => {
    brandFetch.current++
    const fetchId = brandFetch.current
    setFetchingBrands(true)
    axios
      .get(`${NODE_URL}/brands`, {
        params: { search, sort: 'campaigns-desc' },
      })
      .then(res => {
        if (brandFetch.current === fetchId) {
          setAllBrands(res.data)
        }
      })
      .catch(() => {})
      .finally(() => setFetchingBrands(false))
  }
  // #endregion

  return (
    <Wrapper>
      <div className='select-container'>
        {userData.brandProfiles?.length > 1 ? (
          <Select
            defaultValue={
              userData.extraData?.selectedBrand
                ? parseInt(userData.extraData?.selectedBrand)
                : parseInt(userData.brandProfiles[0].brandId)
            }
            onChange={selectBrand}>
            {userData.brandProfiles.map(profile => (
              <Select.Option
                label={profile.brand.name}
                key={profile.brandId}
                value={profile.brandId}>
                {profile.brand.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <p className='brand-name'>{userData.brandProfiles[0].brand.name}</p>
        )}
      </div>

      <nav>
        {routes({ firstCampaignId, numUnreadMessages }).map((route, i) =>
          (route.launchDarklyFlag || route.launchDarklyHideFlag) &&
          (!flags[route.launchDarklyFlag] || flags[route.launchDarklyHideFlag]) ? null : (
            <Link key={i} to={route.path} className={route.className}>
              {!!route.notificationIndicator && <span className='notification-indicator' />}
              {route.icon}
              {route.label}
              {!!route.notificationNum && (
                <span className='notification-num'>
                  ({numeral(route.notificationNum).format('0a')})
                </span>
              )}
            </Link>
          )
        )}
      </nav>

      {userData.role && userData.role === 'administrator' && (
        <div className='admin'>
          <span className='title'>
            Admin
            <img src={admin} alt='' />
          </span>
          <div className='select-brand'>
            <Select
              style={{ width: '100%', textAlign: 'left' }}
              // FIXME: shows as number initially
              value={parseInt(brandId)}
              onChange={selectBrand}
              loading={fetchingBrands}
              placeholder='Search brands'
              showSearch
              filterOption={false}
              onSearch={val => handleSearchBrands(val)}>
              {allBrands.brands
                ?.sort(
                  (a, b) =>
                    b.campaigns.filter(c => c.status === 'publish').length -
                    a.campaigns.filter(c => c.status === 'publish').length
                )
                .map(item => (
                  <Select.Option label={item.name} key={item.id} value={item.id}>
                    {`${item.name} (${item.campaigns.filter(c => c.status === 'publish').length})`}
                  </Select.Option>
                ))}
            </Select>
          </div>
          <Button
            onClick={() => setCreateBrandOpen(true)}
            icon={<PlusOutlined />}
            type='primary'
            style={{ width: '100%' }}>
            Create Brand
          </Button>

          <Modal
            footer={null}
            title='Create a new brand'
            onCancel={() => setCreateBrandOpen(false)}
            destroyOnClose
            open={createBrandOpen}>
            <Formik
              initialValues={{}}
              validationSchema={yup.object().shape({
                name: yup.string().required('Please enter a name'),
                website: yup.string().required('Please enter the website url'),
                description: yup.string().required('Please enter a description'),
              })}
              onSubmit={data => {
                axios
                  .post(`${NODE_URL}/admin/brands`, data)
                  .then(res => {
                    const { brandId } = res.data
                    setCreateBrandOpen(false)
                    selectBrand(brandId)
                  })
                  .catch(err => {
                    setCreateBrandOpen(false)
                    message.error(err.err || 'Sorry, something went wrong.')
                  })
              }}>
              {({ isSubmitting }) => (
                <Form>
                  <FormItem name='name' label='Name'>
                    <Input required name='name' />
                  </FormItem>
                  <FormItem name='website' label='Website'>
                    <Input required name='website' />
                  </FormItem>
                  <FormItem name='description' label='Brand Story (Description)'>
                    <Input.TextArea required name='description' />
                  </FormItem>
                  <Button loading={isSubmitting} htmlType='submit' type='primary'>
                    Create
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  height: calc(100vh - 50px);
  width: 200px;
  overflow: auto;
  background-color: #080830;
  color: white;
  z-index: 9;
  position: relative;
  flex-direction: column;
  display: none;
  .select-container {
    margin: 20px 20px 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    text-align: left;
    p {
      margin: 0;
    }
    .ant-select {
      background: rgba(255, 255, 255, 0.15);
      width: 100%;
      font-size: 1rem;
      border-radius: 5px;
    }
    .ant-select-focused .ant-select-selector {
      box-shadow: none !important;
    }
    .ant-select-selector {
      border: none;
      background: transparent;
    }
    .ant-select-selection-item {
      color: ${props => props.theme.crcoPaleBlue};
    }
    .ant-select-arrow {
      color: ${props => props.theme.crcoPaleBlue};
    }
  }
  .brand-name {
    font-size: 0.9rem;
    opacity: 0.8;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .ant-btn-primary {
    width: 90%;
  }
  .ant-select-arrow {
    top: 45%;
  }
  nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px 20px 0;
    text-align: left;
  }
  .admin {
    margin-top: auto;
    text-align: left;
    padding: 20px 10px;
    .title {
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      img {
        height: 20px;
      }
    }
    .select-brand {
      margin-bottom: 12px;
    }
    button {
      background: #027df0;
      border-color: #027df0;
    }
    button:hover {
      background: #53acff;
      border-color: #53acff;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    display: flex;
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    width: 250px;
  }
`

const Link = styled(NavLink)`
  position: relative;
  color: #fff;
  font-size: 1rem;
  padding: 5px 5px 5px 20px;
  border-radius: 0 20px 20px 0;
  transition: 0.2s ease-in-out;
  &:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
  }
  &.active {
    background: rgba(255, 255, 255, 0.2);
  }
  .anticon {
    margin-right: 10px;
  }
  .notification-indicator {
    background: orange;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 5px;
    left: 15px;
  }
  .notification-num {
    font-size: 0.8rem;
    margin-left: 6px;
  }
  &.beamerTrigger {
    &.active:not(:hover) {
      background: transparent !important;
    }
    &.active:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`

export default Sidebar
