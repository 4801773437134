import { SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import CreatorAvatar from '../components/general/CreatorAvatar'
import { Hamburger } from '../components/general/Hamburger'
import { BOOK_DEMO_URL } from '../constants'
import { GlobalContext } from '../contexts/GlobalContext'
import { UserContext } from '../contexts/UserContext'
import creatorcoLogo from '../images/logo.svg'

export const Header = () => {
  const { isLoggedIn, fetchCurrentUserData, userContextConfig } = useContext(UserContext)
  const { headerRef } = useContext(GlobalContext)
  const { data: userData } = useQuery('current-user', fetchCurrentUserData, userContextConfig)

  const DashboardMenu = (
    <Menu>
      <Menu.Item>
        <MenuLink to='/profile'>
          <UserOutlined />
          Profile
        </MenuLink>
      </Menu.Item>
      <Menu.Item>
        <MenuLink to='/account'>
          <SettingOutlined />
          Account
        </MenuLink>
      </Menu.Item>
      <Menu.Item>
        <MenuLink to='/logout' className='logout'>
          <LogoutOutlined />
          Sign Out
        </MenuLink>
      </Menu.Item>
    </Menu>
  )

  return (
    <Wrapper ref={headerRef}>
      <div className='inner'>
        <a className='logo' href='https://creator.co'>
          <img src={creatorcoLogo} alt='creatorco' />
        </a>
        <div className='nav'>
          <a
            className='link'
            href='https://app.creator.co/collaboration-hub'
            target='_blank'
            rel='noopener noreferrer'>
            Collaboration Hub
          </a>
          <Link to='/pricing' className='link'>
            Pricing
          </Link>
          <a
            className='link'
            href='https://creator.co/blog'
            target='_blank'
            rel='noopener noreferrer'>
            Blog
          </a>
          <a
            className='link'
            href='https://creator.co/brand-contact'
            target='_blank'
            rel='noopener noreferrer'>
            Contact
          </a>
          <a
            className='link'
            href='https://brands-creatorco.zendesk.com/hc/en-us'
            target='_blank'
            rel='noopener noreferrer'>
            Help Center
          </a>
          {isLoggedIn && userData ? (
            <div className='user'>
              <Dropdown
                getPopupContainer={trigger => trigger.parentNode}
                overlay={DashboardMenu}
                trigger={['click']}>
                <div className='dropdown'>
                  <CreatorAvatar
                    className='avatar'
                    size={30}
                    id={userData?.id}
                    color='#027df0'
                    initials={`
                  ${userData.firstName?.charAt(0).toUpperCase()}
                  ${userData.lastName?.charAt(0).toUpperCase()}
                `}
                  />
                  <span className='name'>{`${userData.firstName} ${userData.lastName}`}</span>
                </div>
              </Dropdown>
            </div>
          ) : (
            <div className='ctas'>
              <Link to='/login' className='cta'>
                <Button type='primary'>Log In</Button>
              </Link>
              <Link to='/signup' className='cta'>
                <Button type='secondary'>Sign Up</Button>
              </Link>
              <a href={BOOK_DEMO_URL} target='_blank' rel='noreferrer' className='cta'>
                <Button type='primary'>Request Demo</Button>
              </a>
            </div>
          )}
        </div>
        <Hamburger />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  .inner {
    margin: auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    .logo {
      img {
        height: 20px;
      }
    }
    .nav {
      font-size: 0.9rem;
      display: none;
      .link {
        color: ${props => props.theme.crcoMidnight};
        margin-left: 20px;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        &:hover {
          color: ${props => props.theme.crcoTechBlue};
        }
        img {
          height: 20px;
        }
      }
      .user {
        display: flex;
        align-items: center;
        border-left: 1px solid #e6e6e6;
        padding-left: 20px;
        margin-left: 20px;
        cursor: pointer;
        .dropdown {
          display: flex;
        }
        .name {
          margin-left: 10px;
          display: flex;
          align-items: center;
          &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-style: solid;
            border-color: #23262f;
            border-width: 2px 2px 0 0;
            margin: 0 0 5px 10px;
            transform: rotate(135deg);
            transition: 0.2s ease-in-out;
          }
        }
      }
      .ctas {
        display: flex;
      }
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpointTablet}) {
    .inner {
      .logo img {
        height: 22px;
      }
      .nav {
        display: flex;
        align-items: center;
      }
      .link:first-child {
        display: none;
      }
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpointDesktop}) {
    .inner {
      padding: 0 40px;
      .logo img {
        height: 27px;
      }
      .nav {
        font-size: 1rem;
      }
      .link {
        margin-left: 20px;
        &:first-child {
          display: block;
        }
      }
    }
  }
`

const Link = styled(NavLink)`
  color: ${props => props.theme.crcoMidnight};
  margin-left: 20px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  &.active {
    color: ${props => props.theme.crcoTechBlue};
    border-bottom: 2px solid ${props => props.theme.crcoTechBlue};
  }
  &.cta {
    border: 0;
  }
  &:hover {
    color: ${props => props.theme.crcoTechBlue};
  }
`

const MenuLink = styled(NavLink)`
  .anticon {
    margin-right: 10px;
  }
  .tab-icon {
    height: 15px;
    width: 15px;
    margin-right: 10px;
  }
  &.active {
    color: ${props => props.theme.crcoTechBlue};
  }
  &.logout {
    color: #ff0000;
    &:hover {
      color: #ff0000;
    }
  }
`

export default Header
