import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  DownloadOutlined,
  UpOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Tag, Select } from 'antd'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ManualOptIn } from './tabs/opt-ins/ManualOptIn'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import affiliate from '../../images/icons/affiliate.svg'
import bespoke from '../../images/icons/bespoke.svg'
import giveaway from '../../images/icons/giveaway.svg'
import shoutout from '../../images/icons/shoutout.svg'
import instagram from '../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../images/icons/social/youtube-color-square.svg'
const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select

const CampaignHeader = ({ campaign }) => {
  const { brandId, fetchBrandData } = useContext(BrandContext)

  const history = useHistory()
  const [downloading, setDownloading] = useState(false)
  const [headerExpanded, setHeaderExpanded] = useState(true)
  const thumbnailUrl = campaign.images?.filter(image => image.type === 'primary')[0]?.sizes[1]?.url
  const [socialChannel, setSocialChannel] = useState(campaign.socialChannels?.[0] || 'instagram')

  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const loadExport = () => {
    setDownloading(true)
    axios(`${NODE_URL}/brand-export/opt-ins/${campaign.id}?socialChannel=${socialChannel}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    }).then(async response => {
      const fileName = response.headers.get('file-name')
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      setDownloading(false)
    })
  }

  return (
    <Wrapper>
      <div className='header-nav'>
        <Button onClick={() => history.push('/campaigns')} type='link'>
          <ArrowLeftOutlined /> All Campaigns
        </Button>
        <div className='divider' />
        <Button onClick={() => setHeaderExpanded(!headerExpanded)} type='text'>
          {headerExpanded ? <UpOutlined /> : <DownOutlined />} {campaign.title}
        </Button>
      </div>

      <div className={`campaign-header ${!headerExpanded && 'collapsed'}`}>
        <div className={`campaign-image ${campaign.status}`}>
          <div className='thumbnail' style={{ backgroundImage: `url(${thumbnailUrl})` }} />
          <span>{campaign.status === 'publish' ? 'Active' : 'Paused'}</span>
        </div>
        <div className='campaign-info'>
          <div className='title'>{campaign.title}</div>
          <div className='strategy'>
            {!!campaign.password && (
              <Tag color='volcano'>
                <ExclamationCircleOutlined /> Unlisted
              </Tag>
            )}
            {campaign.socialChannels?.map((channel, i) => (
              <img src={socialIcons[channel]} alt={channel} key={i} />
            ))}
            <img
              className='icon'
              src={
                campaign.strategy === 'shoutout'
                  ? shoutout
                  : campaign.strategy === 'giveaway'
                    ? giveaway
                    : campaign.strategy === 'affiliate'
                      ? affiliate
                      : bespoke
              }
              alt=''
            />
            {campaign.contentFormats?.length && campaign.contentFormats[0].length ? (
              campaign.contentFormats.map(
                format =>
                  !!format.length && (
                    <span key={format} className='format'>
                      {format === 'Video Post' ? 'Video' : format}
                    </span>
                  )
              )
            ) : (
              <span className='format'>Custom</span>
            )}
          </div>
          <div className='payout'>
            {campaign.maxPaidAmount ? (
              <>
                <span className='label'>Payment: </span>
                <span className='amount'>
                  $
                  {campaign.minPaidAmount
                    ? `${campaign.minPaidAmount}-${campaign.maxPaidAmount}`
                    : campaign.maxPaidAmount}{' '}
                  USD
                </span>
              </>
            ) : (
              'No payment.'
            )}
          </div>
          <div className='opt-ins'>
            <div>
              <span>
                <UserOutlined /> <b>{campaign.optIns || 'No'} Opt-Ins</b>
                {!!campaign.activated && ` (${campaign.activated} activated)`}
              </span>
              {brandData?.userHasAgencyPrivileges && <ManualOptIn campaign={campaign} />}
            </div>
            <div className='export'>
              <span className='label'>Export Opt-Ins:</span>
              {!!campaign.socialChannels?.length && (
                <Select
                  name='socialChannel'
                  placeholder='Select a platform'
                  defaultValue={campaign.socialChannels[0]}
                  style={{ width: '150px' }}
                  onChange={e => {
                    setSocialChannel(e)
                  }}>
                  {campaign.socialChannels.map((channel, i) => (
                    <Option value={channel} key={i}>
                      <img
                        src={socialIcons[channel]}
                        alt=''
                        style={{ height: '20px', marginRight: '8px' }}
                      />
                      {channel === 'instagram'
                        ? 'Instagram'
                        : channel === 'tiktok'
                          ? 'TikTok'
                          : channel === 'youtube' && 'YouTube'}
                    </Option>
                  ))}
                </Select>
              )}
              <Button
                type='link'
                icon={<DownloadOutlined />}
                onClick={loadExport}
                loading={downloading}
                disabled={!campaign.optIns}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  .header-nav {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 100%;
    .divider {
      width: 1px;
      min-width: 1px;
      height: 20px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .ant-btn {
      // handle overflow for long campaign names
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        display: initial;
      }
    }
  }
  .campaign-header {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 16px;
    padding: 16px;
    border-radius: 10px;
    max-height: 1000px;
    transition: 0.2s ease-in-out;
    &.collapsed {
      opacity: 0;
      max-height: 0;
      padding: 0 16px;
      margin: 0;
      visibility: hidden;
    }
  }
  .campaign-image {
    background-color: #a2a2a2;
    text-align: center;
    border-radius: 5%;
    padding: 2px;
    width: 110px;
    height: 140px;
    display: flex;
    flex-direction: column;
    color: #fff;
    .thumbnail {
      flex: 1px;
      border-radius: 5%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;
    }
    &.publish {
      background-color: ${props => props.theme.crcoLettuce};
    }
  }
  .campaign-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    .title {
      color: ${props => props.theme.crcoMidnight};
      font-size: 1.2rem;
      font-family: 'Gilroy-ExtraBold', sans-serif;
    }
    .strategy {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      img {
        height: 20px;
      }
      .format {
        background: #ecf6ff;
        color: #027df0;
        display: grid;
        place-content: center;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 0.7rem;
      }
    }
    .payout {
      font-size: 0.8rem;
      margin-top: 5px;
      color: rgba(0, 0, 0, 0.3);
      .amount {
        color: #00c457;
        font-weight: bold;
      }
    }
    .opt-ins {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;
      font-size: 0.9rem;
      border-top: 1px solid #e6e6e6;
      padding-top: 10px;
    }
  }

  .opt-in-btn {
    margin-left: 5px;
  }

  .export {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }
`

export default CampaignHeader
